import React, { useState, useEffect } from "react";
import FormPageWrapper from "../../components/FormPageWrapper";
import Layout from "../../components/Layout";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useActiveSubscriptionQuery,
  usePaymentsByUserQuery,
  useSubscriptionsByUserQuery,
} from "../../features/subscription/subscriptionApiSlice";
import { HeadProps, PageProps } from "gatsby";
import Textbooks from "../../features/frontpage/Textbooks";
import SectionTitle from "../../components/SectionTitle";
import { StaticImage } from "gatsby-plugin-image";
import { CmPayment, CmSubscriptionPaddle } from "../../types";
import { useCurrentUserQuery } from "../../features/user/userApiSlice";
import Seo from "../../features/seo/Seo";
import * as Sentry from "@sentry/gatsby";

const ThankYou = (props: PageProps) => {
  const [hasPaymentDetails, setHasPaymentDetails] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState<CmPayment>();
  const [subscription, setSubscription] = useState<CmSubscriptionPaddle>();
  const { data: currentUser } = useCurrentUserQuery();
  // Include useActiveSubscriptionQuery to trigger refetch so sign-up button won't show.
  const { data: activeSubscription, refetch: refetchActiveSubscription} = useActiveSubscriptionQuery();
  const { data: subscriptions, isFetching: isFetchingSubscriptions } =
    useSubscriptionsByUserQuery(currentUser?.data?.uid ?? skipToken, {
      skip: !!subscription,
      pollingInterval: 1500,
    });
  const {
    data: allPayments,
    error,
    isFetching: isFetchingPayments,
  } = usePaymentsByUserQuery(currentUser?.data?.uid ?? skipToken, {
    skip: hasPaymentDetails,
    pollingInterval: 1500,
  });

  useEffect(() => {
    if (Array.isArray(subscriptions) && !isFetchingSubscriptions) {
      const sub = subscriptions.find(
        (item) => item.is_active && item.bundle === "paddle"
      );
      if (sub?.bundle === "paddle") {
        setSubscription(sub);
        refetchActiveSubscription();
      }
    }
  }, [subscriptions, isFetchingSubscriptions]);

  useEffect(() => {
    if (allPayments && subscription && !isFetchingPayments) {
      const paymentDetails = allPayments.find(
        (payment) =>
          payment.subscription === subscription.id &&
          payment.field_instalments === 1
      );
      if (paymentDetails) {
        setHasPaymentDetails(true);
        setPaymentDetails(paymentDetails);
      }
    }
    if (error) {
      console.log(error);
    }
  }, [allPayments, isFetchingPayments, subscription]);

  useEffect(() => {
    if (subscription && paymentDetails) {
      if (typeof gtag === "function") {
        gtag("event", "purchase", {
          currency: paymentDetails.currency,
          transaction_id: paymentDetails.order_id,
          value: paymentDetails.sale_gross,
          tax: paymentDetails.payment_tax,
          coupon: paymentDetails.coupon,
          items: [
            {
              item_id: paymentDetails.field_subscription_plan_id,
              item_name: subscription.plan_name,
              item_category: subscription.field_billing_type,
              price: paymentDetails.sale_gross - paymentDetails.payment_tax,
            },
          ],
        });
      } else {
        const e = Error(
          "thank-you: purchase event not captured since gtag not a function."
        );
        e.name = "Gtag purchase event";
        Sentry.captureException(e);
      }
    }
  }, [subscription, paymentDetails]);

  if (!paymentDetails?.receipt_url) {
    return (
      <div className="mx-auto relative">
        <div className="block mt-40 pt-20 pb-24 mx-auto flex justify-center">
          <ClimbingBoxLoader size={20} color="#245AB0" />
        </div>
        <h2 className="text-neutral-700 text-center block text-2xl font-bold pb-10">
          Please wait. Setting up your account...
        </h2>
      </div>
    );
  }

  return (
    <Layout>
      <FormPageWrapper title="Success!">
        <div className="mx-auto max-w-[140px]">
          <StaticImage
            src="../../images/rocket-h-400.png"
            alt="Rocket"
            width={140}
          />
        </div>
        <div className="text-center mt-12 mb-8">
          Here is your receipt:
          <a
            className="text-blue-700 hover:text-blue-800 hover:underline inline-block ml-2"
            target="_blank"
            href={paymentDetails.receipt_url}
          >
            receipt
          </a>
        </div>

        <div className="mb-8">
          <SectionTitle
            backgroundStyle="gray-square-end"
            title="Choose your textbook"
          />
        </div>
        <Textbooks />
      </FormPageWrapper>
    </Layout>
  );
};

export default ThankYou;

export const Head = (props: HeadProps) => {
  return (
    <>
      <Seo
        title="Thank you | Giancoli Answers"
        pathname={props.location.pathname}
      />
      <meta name="robots" content="noindex" />
    </>
  );
};
